<template>
  <div class="dubShow">
    <downBtn
      :topShow="true"
      :isWx="isWx"
      @down="down"
      @wechatShow="wechatShow"
    ></downBtn>
    <video
      :src="userDubShow.video ? userDubShow.video : ''"
      controls
      :poster="userDubShow.cover"
      preload
      ref="refVideo"
      id="refVideo"
      controlslist="nodownload"
      x-webkit-airplay="allow"
      webkit-playsinline="true"
      x5-playsinline="true"
      x5-video-player-type="h5"
      x5-video-player-fullscreen="false"
      playsinline="true"
      preload="auto"
      x5-video-orientation="h5"
      autoplay
    >
      浏览器不支持播发该视频
    </video>
    <div class="dubbing-info">
      <div class="top">
        <div class="user-info">
          <img :src="userDubShow.userHeadCode" alt="" />
          <div>{{ userDubShow.userName }}</div>
        </div>
        <div class="i-can" @click="maskShow = true">我要配音</div>
      </div>
      <div class="mid">
        <div class="grade">
          <!-- hardLevel	1:零基础 2:初级 3:中级 4:高级 -->
          {{
            userDubShow.hardLevel == 1
              ? "零基础"
              : userDubShow.hardLevel == 2
              ? "初级"
              : userDubShow.hardLevel == 3
              ? "中级"
              : "高级"
          }}
        </div>
        <div class="video-name">{{ userDubShow.materialTitle }}</div>
      </div>
      <div class="bottom">
        <div class="time">{{ userDubShow.createTime }}</div>
        <div class="number">
          <img v-if="userDubShow.praise" src="../../assets/Hearts.svg" alt="" />
          {{ userDubShow.praise }}
        </div>
      </div>
    </div>
    <div class="more-dubbing">
      <div class="title">更多配音</div>
      <div class="more-dubbing-box" @click="maskShow = true">
        <div
          class="dubbing-item"
          v-for="(item, index) in dubShowAll"
          :key="index"
        >
          <div style="position: relative">
            <img class="dubbing-cover" :src="item.cover" alt="" />
            <div class="video-time">{{ item.videoTime }}</div>
          </div>
          <div class="dubbing-details">
            <div class="name">{{ item.materialTitle }}</div>
            <div class="user-details">
              <img :src="item.userHeadCode" alt="" />
              {{ item.userName }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mask-box pos-fiexd" v-show="maskShow">
      <!-- <div class="mask"></div> -->
      <div class="mask-content p-a-c bg-fff">
        <div class="close text-right">
          <img
            src="@/assets/close.png"
            alt=""
            style="width: 14px; height: 14px"
            @click="changeMask"
          />
        </div>
        <div class="content text-center">
          <div class="text">下载洋葱韩语app</div>
          <div class="btn" @click="down">
            <span>去下载</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import downBtn from "@/components/downBtn.vue";
import mixin from "@/utils/mixin";
import { getDubShowVOPage } from "../../api/dubShow";
export default {
  mixins: [mixin],
  name: "dubShow",
  components: {
    downBtn,
  },
  props: {},
  data() {
    return {
      userDubShow: {},
      dubShowAll: [],
      maskShow: false,
    };
  },
  created() {
    this.getDubShowVOPage();
    this.getDubShowVOPageAll();
  },
  methods: {
    changeMask() {
      this.maskShow = !this.maskShow;
    },
    down() {
    let a =  document.createElement('a')
    a.href = "http://onion-test.jixedu.cn/#/download-app"
    a.click()
    },
    getDubShowVOPage() {
      let id = this.$route.query.id || "";
      let params = {
        current: 1, //	页码
        // dubMaterialId: this.$route.query.type ? "" : id, //	配音秀素材ID
        dubMaterialId: "", //	配音秀素材ID
        size: 1, //	页大小
        type: "", //	type
        // userDubShowId: this.$route.query.type ? id : "", //	配音秀ID
        userDubShowId: id, //	配音秀ID
        userId: "", //	用户Id
      };
      getDubShowVOPage(params).then((res) => {
        if (res.code !== "SUCCESS") return;
        this.userDubShow = this.getCover(res.data.dates)[0] || {};
        // if(this.userDubShow.video){
        //   return this.$message.error("暂无视频")
        // }
      });
    },
    getDubShowVOPageAll() {
      let params = {
        current: 1, //	页码
        dubMaterialId: "", //	配音秀素材ID
        size: 4, //	页大小
        type: "", //	type
        userDubShowId: "", //	配音秀ID
        userId: "", //	用户Id
      };
      getDubShowVOPage(params).then((res) => {
        if (res.code !== "SUCCESS") return;
        this.dubShowAll = this.getCover(res.data.dates);
      });
    },
    // 拼接封面
    getCover(arr) {
      arr.map((item) => {
        item.cover = item.video
          ? item.video + `?x-oss-process=video/snapshot,t_1,m_fast`
          : "";
        return item;
      });
      return arr;
    },
  },
};
</script>

<style scoped lang="scss">
.dubShow {
}
video {
  margin: 37px 0 0;
  height: 233px;
  width: 100%;
  object-fit: fill;
}
.dubbing-info {
  padding: 20px 16px 20px 27px;
  box-sizing: border-box;
  background: #f8faff;
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    .user-info {
      display: flex;
      align-items: center;
    }
    .i-can {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 112px;
      height: 38px;
      background: linear-gradient(314.05deg, #4e6aff 2.46%, #88c9ff 92.98%);
      color: #ffffff;
      border-radius: 21px;
    }
    img {
      margin: 0 14px 0 0;
      width: 41px;
      height: 41px;
      border-radius: 50%;
    }
  }
  .mid {
    display: flex;
    align-items: center;
    margin: 20px 0 6px 0;
    .grade {
      margin-right: 9px;
      // width: 33px;
      height: 22px;
      padding: 0px 10px;
      line-height: 22px;
      text-align: center;
      background: #78bbfb;
      border-radius: 4px;
      font-size: 12px;
      color: #ffffff;
    }
    .video-name {
      font-size: 18px;
      color: #333333;
      font-weight: 500;
    }
  }
  .bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 28px;
    .time {
      font-family: PingFang SC;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #666666;
    }
    .number {
      margin: 0 8px 0 0;
      font-size: 16px;
      img {
        vertical-align: top;
      }
    }
  }
}
.more-dubbing {
  margin: 29px 0 0;
  padding: 0 16px;
  box-sizing: border-box;
}
.title {
  margin: 0 0 16px;
  font-size: 18px;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 500;
  color: #333333;
}
.more-dubbing-box {
  // height: 226px;
  // overflow: auto;
}
.dubbing-item {
  padding: 0 0 16px;
  box-sizing: border-box;
  margin: 0 0 14px;
  display: flex;
  border-bottom: 1px solid #f6f6f6;
  .dubbing-cover {
    width: 171px;
    height: 104px;
    border-radius: 8px;
    display: block;
  }
  .video-time {
    position: absolute;
    right: 4px;
    bottom: 2px;
    font-size: 14px;
    color: #ffffff;
  }
  .dubbing-details {
    margin-left: 20px;
  }
  .name {
    height: 56px;
    line-height: 28px;
    font-size: 18px;
    color: #333333;
    font-weight: 500;
  }
  .user-details {
    margin: 22px 0 0;
    display: flex;
    align-items: center;
    font-family: PingFang SC;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #666666;
    img {
      margin: 0 10px 0 0;
      width: 26px;
      height: 26px;
      border-radius: 50%;
    }
  }
}
</style>
